<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg" style="width:100%">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">와카야마 지역의 매력적인 관광지와 인기 시설을 대상으로 한 가성비 있는 티켓입니다. 유효 기간 내에 대상 시설 중에서 원하는 시설을 3곳 선택하여 이용하실 수 있습니다. E-티켓에 기재된 QR 코드를 제시하시면 간편하게 입장할 수 있습니다.</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">사용방법</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ● 사용방법 : 예약 후 메일로 1장의 PDF를 수령한 후, 이용기간 중 해당 시설에 방문하여 QR코드를 제시한 뒤 교환해 주세요. <br>
          ● 각 시설은 1회 한정으로 이용 가능하며, 동일 시설에 재입장하실 수 없습니다. ● 이용 개시 가능일 : 구입일로부터 <span>90일간</span> <br>
          예시 : <span>7/1</span> 구입 ⇒ <span>이용 가능 기간 : 7/1~9/28</span> <br>
          ● 해당 패스는 첫 번째 시설 이용부터 <span>7일간</span> 유효합니다. <br>
          예시 : 첫 번째 시설 이용일 <span>7/1</span> ⇒ <span>유효기간 7/1~7/7</span> <br>
          ● 이용 가능 시설 : 유효 기간(7일) 내 <span>3개까지 </span> 이용 가능<br>
          ● 1회 주문당 여러 개의 패스를 구입하는 경우, 모든 패스의 유효 기간은 동일하므로 주의하시기 바랍니다. (<span>이용 개시부터 7일간</span>) <br>
          ● 유효 기간은 이용 개시부터 7일간이지만, 구입일로부터 <span>90일</span> 이후에는 이용하실 수 없으므로 주의하시기 바랍니다. <br>
          예시 : 구입일이 <span>7/1</span>인 경우, <span>7/1~9/28</span>에만 이용 가능합니다. <span>9/27</span>부터 이용 개시하셔도 <span>9/28</span>까지만 이용하실 수 있습니다. <br>
          ● 미사용 시설은 환불되지 않습니다. <br>
          ● 각 시설의 주의사항, 영업시간, 정기휴일 등은 다음 링크에서 확인해 주세요. 
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='주의사항'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='티켓교환장소' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='공식 사이트' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfwakayama/kr/havefun_title_kr.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfwakayama/01_en.png'),
          title: ['NO PHONE NO LIFE 여행 ~나만의 와카야마 관광~'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '관광교류센터에 와카야마 조이 패스를 제시해 주세요.5 Chome Misonocho, Wakayama わかちか広場 (관광교류센터)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.wakayamakanko.com/wakayama_asobi/npnl/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['포함 내용 : 스마트폰 주머니, 클리어파일, 백지도, 전용 가방, 기념품'] },
                { cid: 2, text: ['체험 시간 : 9:00-17:00 *예약 불필요'] },
                { cid: 3, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.<br>※ 정기 휴일 : 12월 29일~1월 3일'] },
                ]
            }
          ]
        },
        {
          id: 2,
          img: require('@/assets/images/hfwakayama/02_en.png'),
          title: ['와카야마 라멘+교자 or 베지테리언 라멘'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '주문 시, 와카야마 조이 패스를 제시해 주세요.5-chōme-7-12 Misonochō, Wakayama'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.instagram.com/menyahishio_jrwakayamaekimae/?igshid=NTc4MTIwNjQ2YQ%3D%3D'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간 및 정기 휴일은 변경될 수 있으므로 방문 전에 확인해 주세요.<br>※ 정기 휴일 : 12월 29일~1월 3일'] },
                { cid: 2, text: ['베지테리언 라멘에는 교자(만두)가 포함되지 않습니다.'] },
                { cid: 3, text: ['만석으로 인해 이용할 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfwakayama/03_en.png'),
          title: ['전통 기슈 칠기 산업회관 "우루와시칸" 1,000엔 할인 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '계산 시, 와카야마 조이 패스를 제시해 주세요.222 Funoo, Kainan, Wakayama'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.kishusikki.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['요금 차액이 발생할 경우, 현금으로 결제해 주세요.'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 3, text: ['칠기 마키에 체험을 희망하시는 분은 사전에 전화로 신청해 주세요.'] },
                { cid: 4, text: ['칠기 마키에 체험 비용은  칠기 종류에 따라 다릅니다.'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfwakayama/04_en.png'),
          title: ['기미이데라 특별 관람권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '매표소에서 와카야마 조이 패스를 제시해 주세요.1201 Kimiidera, Wakayama'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.kimiidera.com/worship/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['포함 내용 : 관람료, 불전 전망 회랑 입장료, 대광명전 관람료, 왕복 케이블카 요금'] },
                { cid: 2, text: ['기도, 불공, 관람료 및 참배 시간이 각각 다르므로 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 3, text: ['대광명전은 비공개인 날이 있습니다.'] },
                { cid: 4, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfwakayama/05_en.png'),
          title: ['가이난시 특산품 관광센터 "가이부쓰쿤" 1,000엔 이용권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '계산 시, 와카야마 조이 패스를 제시해 주세요.51-2 Nataka, Kainan, Wakayama (JR가이난역 구내)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.kainankanko.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['요금 차액이 발생할 경우, 현장에서 결제해 주세요.'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 3, text: ['실권으로 교환하지 않아도 됩니다. 계산 시에 와캬아마 조이 패스를 제시해 주세요.'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfwakayama/06_en.png'),
          title: ['호텔 그란비아 와카야마 호텔카페 패스워드 "케이크 세트"'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '계산 시, 와카야마 조이 패스를 제시해 주세요.5 Chome-18 Tomodacho, Wakayama'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.jrwesthotels.com/ko/hotels/wakayama/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['포함 내용 : 케이크, 드링크'] },
                { cid: 2, text: ['요금 차액이 발생할 경우, 현장에서 결제해 주세요.'] },
                { cid: 3, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.<br>※ 임시 휴업 및 만석 등 여러 사정으로 인해 이용할 수 없는 경우가 있습니다.'] },
                { cid: 4, text: ['상품 내용의 사진은 참고용으로 실제 제공되는 상품과 다를 수 있습니다.'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfwakayama/07_en.png'),
          title: ['렌터사이클 E-bike 1,000엔 할인 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '나치카쓰우라 관광안내소에서 와카야마 조이 패스를 제시해 주세요.6-chōme-1-4 Tsukiji, Nachikatsuura, Higashimuro District, Wakayama (나치카쓰우라 관광안내소)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://nachikan.jp/activity/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 이용하실 수 있습니다.'] },
                { cid: 2, text: ['요금은 이용 시간 및 자전거 종류에 따라 다릅니다.'] },
                { cid: 3, text: ['예약 없이 이용하실 수 있습니다. 자전거 수는 한정되어 있으므로 꼭 이용하고 싶으신 분은 사전에 travel@nachikan.jp으로 메일로 문의해 주세요.'] },
                { cid: 4, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 5, text: ['요금 차액이 발생할 경우, 현장에서 결제해 주세요.'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfwakayama/08_en.png'),
          title: ['헤이안시대 의상 체험 1,000엔 할인 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '나치카쓰우라 관광안내소 또는 다이몬자카 관광안내소에서 와카야마 조이 패스를 제시하여 쿠폰으로 교환해 주세요.',
                '6-chōme-1-4 Tsukiji, Nachikatsuura, Higashimuro District, Wakayama (나치카쓰우라 관광안내소)',
                '3034-2 Ichinono, Nachikatsuura, Higashimuro District, Wakayama (다이몬자카 관광안내소)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://nachikan.jp/activity/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 이용하실 수 있습니다.'] },
                { cid: 2, text: ['요금 차액이 발생할 경우, 현금으로 결제해 주세요.'] },
                { cid: 3, text: ['다이몬자카차야(大門坂茶屋)에는 물품보관함이 없습니다. 이용객의 옷만 맡아드리며, 귀중품은 보관해 드리지 않습니다. 사전에 양해 부탁드립니다. (※예약 불필요)'] },
                { cid: 4, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfwakayama/09_en.png'),
          title: ['"센류(川柳)" 식사 이용권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '주문 시, 와카야마 조이 패스를 제시해 주세요.Wakayama, Higashimuro District, Nachikatsuura, Tsukiji, 4-chōme-3-27'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://nachikan.jp/restaurant/1580/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['꽁치 회와 가쓰동 중 선택하시거나 또는 500엔을 추가하시면 명물 마구로동을 선택하실 수 있습니다. (모든 메뉴 국 포함)'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 3, text: ['만석으로 인해 이용할 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 10,
          img: require('@/assets/images/hfwakayama/10_en.png'),
          title: ['"니기와이 시장" 스페셜 런치 메뉴'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '가게명: "마구로노 와키구치(鮪の脇口)"주문 시, 와카야마 조이 패스를 제시해 주세요.7-chōme-12 Tsukiji, Nachikatsuura, Higashimuro District, Wakayama'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://nigiwaiichiba.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.<br>※ 정기 휴일 : 화요일'] },
                { cid: 2, text: ['라스트 오더는 15:30입니다.'] },
                { cid: 3, text: ['만석으로 인해 이용할 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 11,
          img: require('@/assets/images/hfwakayama/11_en.png'),
          title: ['기노마쓰시마 관광 유람선'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '기노마쓰시마 관광 유람선 매표소에서 와카야마 조이 패스를 제시해 주세요.442-20 Katsuura, Nachikatsuura, Higashimuro District, Wakayama'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['http://kinomatsushima.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['운행 상황 및 시간표를 사전에 공식 홈페이지에서 확인해 주세요.<br>※ 정기 휴무 : 수요일 (단, 기상 악화 및 비수기, 임시 휴업 있음)'] },
                { cid: 2, text: ['만석으로 인해 이용할 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfwakayama/12_en.png'),
          title: ['어드벤처 월드 1일 입장권 1000엔 할인 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒649-2201 와카야마현 니시무로군 시라하마초 가타타 2399 (티켓 판매소)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.aws-s.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000엔 할인 쿠폰을 사용하실 수 있습니다. (입장권 구입 시에만 사용 가능)'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요.'] },
                { cid: 3, text: ['요금 차액이 발생하는 경우, 현장에서 결제가 필요합니다.'] },
                ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfwakayama/13_en.png'),
          title: ['SHIRAHAMA KEY TERRACE HOTEL SEAMORE (1일 입욕권)'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '호텔 프런트에서 와카야마 조이 패스를 제시하여 티켓으로 교환해 주세요.1821 Shirahama, Nishimuro District, Wakayama'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.keyterrace.co.jp/en/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.'] },
                { cid: 2, text: ['만원으로 인해 이용할 수 없는 경우가 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 14,
          img: require('@/assets/images/hfwakayama/14_en.png'),
          title: ['시라하마 해중 전망탑 (코럴 프린세스) 입장권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '호텔 프런트에서 와카야마 조이 패스를 제시하여 티켓으로 교환해 주세요.1821 Shirahama, Nishimuro District, Wakayama'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.keyterrace.co.jp/en/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지에서 확인해 주세요.<br>※ 기상 악화로 인해 임시 휴업하는 경우가 있습니다.'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFWakayama&l=kr')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-wakayama-1-week-free-pass/kr', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>